'use strict';

angular.module('cpformplastApp.clients')
    .controller('clientManagementController', function($scope, DataManager, $state, Notification) {

  $scope.clients = [];

  $scope.clientTableButtons = [{
    'icon':'plus',
    'title':'Ajouter',
    'function': () => {
      $state.go('clients/client-management/client-creation');
    }
  }];

  $scope.clientTableStructure = [
    {
      'title':'Nom',
      'value':'name',
      'filter':'alpha'
    },
    {
      'title':'Vendeur',
      'value':'salesman',
      'filter':'alpha'
    },
    {
      'title':'Contact',
      'value':'contact',
      'filter':'alpha'
    }
  ];

  $scope.init = function() {
    DataManager.fetchClients().then((data) => {
      $scope.setClientsFromData(data);
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un probème est survenu lors du chargement des items');
    });
  };

  $scope.selectClient = function(client) {
    $state.go('clients/client-management/client-creation', {'clientId': client._id});
  };

  $scope.setClientsFromData = function(data) {
    $scope.clients = data.map(client => ({
      _id: client._id,
      name: client.name,
      salesman: client.salesman.name,
      contact: client.contact.name + ' (' + client.contact.phoneNumber + ')'
    }));
  };

  $scope.init();
});
